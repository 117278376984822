<template>
  <!-- profile css 사용 -->
  <div class="container profile">
    <div class="ui-mt-3 ui-mb-3">
      <h4>사업 형태 선택</h4>
      <template v-for="(option, key) in options">
        <left-check-box
          :key="key"
          class="ui-mt-0 ui-mb-0"
          :text="option"
          v-model="input.option[key]"
          @click="result => {
            if (result.checked) {
              for (let i in input.option) {
                input.option[i] = i === key
              }
              result.update()
            }
          }"
          />
      </template>
    </div>

    <div class="ui-border-line ui-mb-6" style="height: 10px"></div>

    <div class="profile-info">
      <h4>지원 금액</h4>
      <p>헤이비글 수수료 10%와 부가세를 포함하여 입력하세요.</p>
      <div class="ui-border-line"></div>
      <div class="calc-group">
        <number-input
          :value="input.price"
          @input="val => input.price = val"
          placeholder="지원 금액을 입력해주세요."
          required
          />
        <button
          class="ui-btn"
          :disabled="!validator"
          @click="() => {
            form.option[0] = input.option[0]
            form.option[1] = input.option[1]
            form.price = input.price
            showCalc=true
          }"
          >계산하기</button>
      </div>
    </div>

    <div class="profile-info" v-if="showCalc">
      <div class="ui-border-line ui-mb-6" style="height: 10px"></div>
      <h4>계산 결과</h4>
      <div class="ui-border-line ui-mt-3 ui-mb-1"></div>
      <!--  -->
      <div class="info-block calc-tooltip">
        <ul>
          <li>공급가 <icon src="img/offer/help.png" width="22px" height="34px" :cover="false"
            @click="() => showTooltip('supplyPrice')"
            :style="{
              display: 'inline-block',
              marginTop: '-2px',
              marginBottom: '-10px',
            }"
            />
            <div
              class="calc-tooltip-toast toasted primary default"
              :class="{ show: tooltip.supplyPrice }"
              >지원한 금액에서 부가가치세(10%)를 제외한 금액입니다.</div>
          </li>
          <li class="float-right">{{$lib.addComma(supplyPrice)}}원</li>
        </ul>
        <div class="ui-border-line ui-mt-1 ui-mb-1"></div>
      </div>
      <!--  -->
      <div class="info-block">
        <ul>
          <li>헤이비글 수수료 (10%)</li>
          <li class="float-right">{{$lib.addComma(fees)}}원</li>
        </ul>
        <div class="ui-border-line ui-mt-1 ui-mb-1"></div>
      </div>
      <!--  -->
      <div class="info-block" v-if="form.option[1]">
        <ul>
          <li>원천징수세 (3.3%)</li>
          <li class="float-right">{{$lib.addComma(withholdingTax)}}원</li>
        </ul>
        <div class="ui-border-line ui-mt-1 ui-mb-1"></div>
      </div>
      <!--  -->
      <div class="info-block">
        <ul>
          <li class="color-lavender ui-weight-6"
            :style="{fontSize: '24px'}"
            >예상 수익 금액{{form.option[0] ? ` (부가세 제외)`: ''}}</li>
          <li class="color-lavender ui-weight-6 float-right"
            :style="{fontSize: '24px'}"
            >{{$lib.addComma(incomePrice)}}원</li>
        </ul>
        <div class="ui-border-line ui-mt-1"></div>
      </div>
      <!--  -->

    </div>

    <div class="ui-border-line" style="height: 10px"></div>

    <div class="profile-teamDesc">
      <h4>꼭 읽어보세요!</h4>
      <p class="ui-p-indent-2">1. 부가세법과 소득세법 변동에 따라 금액이 달라질 수 있습니다.</p>
      <p class="ui-p-indent-2">2. 개인사업자, 법인사업자의 경우 부가세 포함 금액이 지급되며 지급 받을 금액에 대한 세금계산서 발행이 필요합니다.</p>
      <p class="ui-p-indent-2">3. 헤이비글 수수료 및 지급에 대한 자세한 내용은 <span class="color-lavender text-underline" @click="$router.push('/etc/fee-information')">수수료 안내</span>를 참고하세요</p>
    </div>

  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import Icon from '@/components/common/Icon'
import FooterBox from '@/components/common/FooterBox'
import LeftCheckBox from '@/components/common/LeftCheckBox'
import NumberInput from '@/components/common/NumberInput'

export default {
  name: 'CalcFee',
  components: {
    PageHeader,
    Icon,
    FooterBox,
    LeftCheckBox,
    NumberInput,
  },
  data() {
    return {
      showCalc: false,
      options: {
        0: '사업자입니다.(개인사업자,법인사업자)',
        1: '사업자가 아닙니다.',
      },
      form: {
        price: 0,
        option: {
          0: false,
          1: false,
        },
      },
      input: {
        price: 0,
        option: {
          0: false,
          1: false,
        },
      },
      tooltip: {
        supplyPrice: false,
      },
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.userData
    },
    isClient() {
      return this.userData.category === 'client'
    },
    isArtist() {
      return this.userData.category === 'artist'
    },
    validator() {
      return this.input.price !== '0원' && (this.input.option[0] || this.input.option[1])
    },
    price() {
      let price = this.form.price
      if (typeof price === 'string') {
        price = price.replace(/,/g, '')
        price = price.replace(/원/g, '')
      }
      return Number(price || 0)
    },
    // 공급가
    supplyPrice() {
      return Math.round(this.price / 1.1)
    },
    // 헤이비글 수수료
    fees() {
      return Math.round(this.supplyPrice * 0.1)
    },
    // 원천징수세
    withholdingTax() {
      return Math.round((this.supplyPrice - this.fees) * 3.3 / 100)
    },
    // 예상 수입
    incomePrice() {
      if (this.form.option[0]) {
        return Math.round(this.supplyPrice - this.fees)
      } else {
        return Math.round(this.supplyPrice - this.fees - this.withholdingTax)
      }
    },
  },
  created() {
  },
  methods: {
    showTooltip(name) {
      this.tooltip[name] = true
      setTimeout(() => {
        this.tooltip[name] = false
      }, 2000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

#page {
  > .container.profile {
    .profile-info .info-block ul li {
      &:first-child {
        width: auto;
        color: #464646;
      }
      &:nth-child(2) {
        font-weight: 500;
      }
    }
  }

  h4 {
    font-weight: 500;
  }

  input {
    padding: 0;
    width: 100%;
    border: 0;
    font-size: 2rem;
    line-height: 3rem;
    height: 8rem;
  }

  p {
    color: #979797;
    font-size: 1.8rem;
  }

  .calc-group {
    input {
      max-width: 320px;
      width: calc(100% - 140px);
    }
    button {
      display: block;
      float: right;
      width: 140px;
      height: 40px;
      font-size: 18px;
      color: $color-deepLavender;
      background-color: transparent;
      border: 1px solid $color-deepLavender;
      border-radius: 20px;
      padding: 0;
      margin: 20px 0;
      &:disabled {
        color: #b6b6b6;
        border-color: #b6b6b6;
      }
    }
  }

  .calc-tooltip {
    position: relative;
    .calc-tooltip-toast {
      opacity: 0;
      position: absolute;
      top: -9rem;
      left: 0;
      right: 0;
      transition: 0.3s;
      line-height: normal;
      &.show {
        opacity: 1;
      }
    }
  }
}
</style>
